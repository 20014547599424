import React from "react";

const About = () => {
  const teamMembers = [
    { name: "Dristi Ghimire", role: "President", imageUrl: "images/dristi.jpg" },
    { name: "Ada Thapa", role: "Vice President", imageUrl: "images/ada.jpg" },
    { name: "Anjela Manandhar", role: "Secretary", imageUrl: "images/anjela.jpg" },
    { name: "Monika Shah", role: "Treasurer", imageUrl: "images/monika.jpg" },
    { name: "Prabin Subedi", role: "Board Member", imageUrl: "images/prabin.jpg" },
    { name: "Aachal Thapa", role: "Board Member", imageUrl: "images/aachal.jpg" },
  ];

  return (
    <div>
      <h1>About Us</h1>
      <p>
        Sahayog is a newly founded non-profit organization, driven by a passionate group of animal lovers living in the USA, united by our love for animals and our deep connection to Nepal.
        Our mission is to support and care for animals in Nepal by providing a safe haven for those in need and educating the community on the importance of animal welfare and environmental conservation.
        Although we are just beginning our journey, our vision is clear: to create a world where every animal is treated with respect and kindness. Join us as we work together to make a difference.
      </p>

      <section id="team" className="team-gallery">
        <h2>Meet Our Team</h2>
        <div className="team-members">
          {teamMembers.map((member, index) => (
            <div key={index} className="team-member">
              <img src={member.imageUrl} alt={`${member.name}`} />
              <h3>{member.name}</h3>
              <p>{member.role}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default About;
