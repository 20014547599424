import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const Home = () => {
    return (
        <div>
            <h1>Welcome to Sahayog</h1>
            <p>
                We are a non-profit organization committed to making a positive impact on
                the lives of animals in Nepal. As a 501(c)(3) tax-exempt organization, your
                contributions are fully tax-deductible, helping us maximize our efforts.
            </p>
            <p>
                Explore our website to learn more about our mission, our projects, and how you
                can get involved.
            </p>
            <Carousel
                showThumbs={false}
                infiniteLoop
                autoPlay
                interval={5000}
                showStatus={false}
                dynamicHeight={true}
                emulateTouch
            >
                <div>
                    <img src="/images/carousel1.jpg" alt="Animal Shelter Project" />
                </div>
                <div>
                    <img src="/images/carousel2.jpg" alt="Community Education Event" />
                </div>
                <div>
                    <img src="/images/carousel3.jpg" alt="Rescue Mission" />
                </div>
            </Carousel>
        </div>
    );
};

export default Home;
