import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Help from "./components/Help";
import "./App.css";

function App() {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <Router>
      <div>
        {/* Navigation */}
        <div className="logo-container">
          <Link to="/">
            <img src="/images/logo.jpg" alt="Sahayog Logo" />
          </Link>
        </div>
        <nav>
          {/* Hamburger Icon */}
          <div className={`hamburger ${isMenuOpen ? "active" : ""}`} onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>

          {/* Navigation Links */}
          <div className={`nav-links ${isMenuOpen ? "active" : ""}`}>
            <Link to="/" onClick={() => setMenuOpen(false)}>Home</Link>
            <Link to="/about" onClick={() => setMenuOpen(false)}>About</Link>
            <Link to="/contact" onClick={() => setMenuOpen(false)}>Contact</Link>
            <Link to="/help" onClick={() => setMenuOpen(false)}>How can you Help</Link>
          </div>
        </nav>

        {/* Routes */}
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/help" element={<Help />} />
          </Routes>
        </main>

        {/* Footer */}
        <footer className="footer">
          &copy; 2024 Sahayog. All rights reserved. Designed by{" "}
          <a
            href="https://www.linkedin.com/in/aachal-thapa-3742b692/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#FFD700", textDecoration: "none", fontWeight: "bold" }}
          >
            Aachal Thapa
          </a>
        </footer>
      </div>
    </Router>
  );
}

export default App;
